import {currentState, updateCurrentState} from '@github-ui/history'

export const generateAppId = () => {
  const historyAppId = currentState().appId
  // When first loading an app, generate a new uuid to identify it
  if (!historyAppId || historyAppId === 'rails') {
    return crypto.randomUUID()
  }

  // If the app is being restored from History, keep its uuid
  return historyAppId
}

export const registerAppId = (uuid: string) => {
  updateCurrentState({appId: uuid})
}
